<template>
  <div class="header">
    <div class="header__menu">
      <h2 class="header__text">YOUR BEAUTY PROFILE</h2>
      <ul class="list-inline">
        <li v-for="(item, i) in listBeauty" :key="i" class="list-inline__item">{{ item.name }}</li>
      </ul>
    </div>

    <div class="header__info">
      <GImageUser :username="userProfile.user_name" :src="userProfile.image" class-name="header__ava" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaskStationHeader',
  data() {
    return {
      listBeauty: '',
    };
  },
  computed: {
    userProfile() {
      return this.$store.state.auth.userProfile;
    },
  },
  created() {
    const listBeauty = [];
    this.$SO.each(this.userProfile.beauty, (key, obj) => {
      this.$SO.each(obj.subtags, (keysubtags, objChild) => {
        listBeauty.push(objChild);
      });
    });
    this.listBeauty = listBeauty;
  },
};
</script>
