<template>
  <div class="related-content">
    <h2 class="related-content__heading"><img src="/static/img/lipstickbar/button-play.svg" />{{ heading }}</h2>

    <div v-if="isLoadingFinish" class="swiper-universal">
      <div v-if="$SO.size(relatedContent)" ref="cardSlide" class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(items, key) in relatedContent" :key="key" class="swiper-slide">
            <div class="row">
              <div v-for="(item, key) in items" :key="key" class="col-xs-6">
                <div v-if="item.object_type === 'article' && $SO.size(item.article)" class="card">
                  <div class="card__header">
                    <GImageUser :username="item.owner.user_name" :src="item.owner.image" class-name="card__ava" />

                    <div class="card__author-information">
                      <a
                        :href="`${$store.state.SOCO_WEB_URL}/${item.owner.user_name}/timeline`"
                        target="_blank"
                        class="card__author-fullname"
                        >{{ item.owner.name }}</a
                      >
                      <p class="card__datetime">{{ item.created_at | dMyyyy }}</p>
                    </div>
                  </div>

                  <div :style="{ backgroundImage: `url(${item.article.image_s3})` }" class="card__image-article"></div>

                  <div class="card__body">
                    <a
                      :href="$store.state.SOCO_WEB_URL + $options.filters.generatedArticleUrl(item)"
                      class="card__title"
                      target="_blank"
                      >{{ item.article.post_title }}</a
                    >
                    <a
                      :href="$store.state.BJ_WEB_URL + '/category/' + item.article.category[0].slug + '/'"
                      target="_blank"
                      class="card__category"
                      >{{ item.article.category_name }}</a
                    >
                  </div>
                </div>

                <div v-if="item.object_type === 'video' && $SO.size(item.video)" class="card">
                  <div class="card__header">
                    <a :href="`${$store.state.SOCO_WEB_URL}/${item.owner.user_name}/timeline`" target="_blank"
                      ><img :src="item.owner.image" class="card__ava"
                    /></a>

                    <div class="card__author-information">
                      <a
                        :href="`${$store.state.SOCO_WEB_URL}/${item.owner.user_name}/timeline`"
                        target="_blank"
                        class="card__author-fullname"
                        >{{ item.owner.name }}</a
                      >
                      <p class="card__datetime">{{ item.created_at | dMyyyy }}</p>
                    </div>
                  </div>

                  <div
                    :style="{
                      backgroundImage: `url(https://img.youtube.com/vi/${item.video.youtube_id}/hqdefault.jpg)`,
                    }"
                    class="card__image-article youtube-icon-wrapper"
                  >
                    <img src="/static/img/mask-station/youtube-icon.svg" class="youtube-icon" />
                  </div>

                  <div class="card__body">
                    <a
                      :href="$store.state.SOCO_WEB_URL + $options.filters.generatedVideoUrl(item)"
                      class="card__title"
                      target="_blank"
                      >{{ item.video.post_title }}</a
                    >
                    <a
                      :href="$store.state.BJ_WEB_URL + '/bjtv_category/' + item.video.category[0].slug"
                      target="_blank"
                      class="card__category"
                      >{{ item.video.category_name }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Add Pagination -->
        <div class="card--swiper-pagination swiper-pagination"></div>
      </div>
      <div v-else class="">
        <p class="text-center">No Related Content Found</p>
      </div>
    </div>
    <div v-else style="margin: 30px 0">
      <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
let cardSlide;

export default {
  name: 'RelatedContent',
  props: {
    heading: {
      required: true,
      type: String,
    },
    relatedContent: {
      required: true,
      type: Array,
    },
    isLoadingFinish: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    cardSlide = new Swiper(this.$refs.cardSlide, {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: false,
      pagination: {
        el: '.card--swiper-pagination',
        clickable: true,
      },
    });
  },
};
</script>
