<template>
  <div class="page--mask-station product-detail">
    <div v-if="$SO.size(auth.userProfile)">
      <Header v-if="$SO.size(auth.userProfile.beauty)" />

      <div v-if="productDetail.isLoadingFinish.product" class="body">
        <div class="container-mask-station">
          <router-link to="/mask-station/beauty-profile" class="back-to">BACK</router-link>
          <Logout />

          <template v-if="auth.processingLogout === false">
            <ProductDisplay
              :attributes-image="productDetail.data.attributesImage || []"
              :attributes-product="productDetail.data.attributesProduct || []"
              :group-active-product-attribute="groupActiveProductAttribute"
              :product="productDetail.data.product"
            />

            <div class="reviews">
              <h2 class="reviews__heading">
                <img src="/static/img/lipstickbar/reviews.svg" />USER'S REVIEWS<!--({{ totalReview > 10 ? '10' : (totalReview ? totalReview : '0') }})-->
              </h2>

              <div v-if="productDetail.isLoadingFinish.reviews" class="reviews__slide swiper-universal">
                <Reviews :reviews-product="reviewsProduct || []" />
              </div>

              <div v-else style="margin: 30px 0">
                <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
              </div>
            </div>

            <RelatedContent
              heading="RELATED TO THIS PRODUCT"
              :related-content="productDetail.data.relatedContent || []"
              :is-loading-finish="productDetail.isLoadingFinish.relatedContent"
            />
          </template>
          <template v-else>
            <LogoutState />
          </template>
        </div>
      </div>
      <div v-else class="body">
        <div class="container-mask-station">
          <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
        </div>
      </div>
    </div>
    <div v-else>
      <div id="loaderspinwrap" class="loaderspinwrap" style="padding: 30px 0"><div class="loaderspin"></div></div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/socomask/global/header';
import LogoutState from '@/components/socomask/global/logout-state';
import Logout from '@/components/socomask/global/logout';
import ProductDisplay from '@/components/global/product-display/product-display';
import Reviews from '@/components/global/reviews/reviews';
import RelatedContent from '@/components/global/related-content/related-content';

export default {
  name: 'MaskStationProductDetail',
  components: {
    Header,
    Logout,
    LogoutState,
    ProductDisplay,
    Reviews,
    RelatedContent,
  },
  data() {
    return {
      isAttributeNotChange: true,
    };
  },
  computed: {
    activeAttribute() {
      return this.$store.state.productDetail.data.activeAttribute;
    },
    auth() {
      return this.$store.state.auth;
    },
    groupActiveProductAttribute() {
      let attributeIdMerge = '';

      if (this.isAttributeNotChange && this.$SO.size(this.product.default_product_attribute)) {
        this.$SO.each(this.product.default_product_attribute.attribute, (key, obj) => {
          attributeIdMerge += obj.id_attribute;
        });
      } else {
        this.$SO.each(this.activeAttribute, (prop, val) => {
          attributeIdMerge += val;
        });
      }

      return attributeIdMerge;
    },
    product() {
      return this.$store.state.productDetail.data.product;
    },
    productDetail() {
      return this.$store.state.productDetail;
    },
    productId() {
      return this.$route.params.product_id;
    },
    reviewsProduct() {
      return this.$store.state.productDetail.data.reviews;
    },
    expired_token() {
      return Cookies.get('token');
    },
    totalReview() {
      return this.$store.state.productDetail.data.totalReview;
    },
  },
  watch: {
    activeAttribute() {
      if (this.$SO.size(this.activeAttribute)) {
        this.isAttributeNotChange = false;
      }
    },
    product() {
      if (this.productDetail.data.product) {
        this.$store.dispatch('getReviewsProduct');
        this.$store.dispatch('generatedArrayProductAttribute');
      }
    },
  },
  created() {
    if (!this.$SO.size(this.auth.userProfile) || !this.expired_token) {
      this.$store.dispatch('checkLogin', { token: Cookies.get('token'), home: 'maskstationHome' });
    }
    this.$store.dispatch('getProductDetail', { productId: this.productId, pageName: '' });
    this.$store.dispatch('getRelatedContent');
  },
};
</script>

<style lang="scss">
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #000;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
</style>
