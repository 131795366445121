<template>
  <div>
    <a v-if="auth.processingLogout" href="" class="text-logout">LOGGING OUT...</a>
    <a v-else href="" class="text-logout" @click.prevent="logout">LOG OUT</a>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('userLogout', 'maskstationHome');
    },
  },
};
</script>
